import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";


import {CheckboxModule} from 'primeng/checkbox';
import {InputTextModule} from 'primeng/inputtext';
import {TableModule} from 'primeng/table';
import {ProgressBarModule} from 'primeng/progressbar';
import {SliderModule} from 'primeng/slider';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {TabViewModule} from 'primeng/tabview';
import {ButtonModule} from 'primeng/button';
import {InputSwitchModule} from 'primeng/inputswitch';
import {DialogModule} from 'primeng/dialog';
import {PasswordModule} from 'primeng/password';
import {CardModule} from 'primeng/card';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {CalendarModule} from 'primeng/calendar';
import {ImageModule} from 'primeng/image';
import { NgxSpinnerModule } from "ngx-spinner";
import {RadioButtonModule} from 'primeng/radiobutton';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {CarouselModule} from 'primeng/carousel';
import {TimelineModule} from 'primeng/timeline';
import {FileUploadModule} from 'primeng/fileupload';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { FieldsetModule } from 'primeng/fieldset';
import { ChartModule } from 'primeng/chart';
import { PanelModule } from 'primeng/panel';
import { SkeletonModule } from 'primeng/skeleton';
import { DividerModule } from 'primeng/divider';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';

import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CustomUploaderComponent } from './components/custom-uploader/custom-uploader.component';
import { CustomValidationDirective } from './directives/custom-validation.directive';
import { CommonLoaderComponent } from './components/common-loader/common-loader.component';
import { StatusIconComponent } from './components/status-icon/status-icon.component';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    InputTextModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    CustomUploaderComponent,
    CustomValidationDirective,
    CommonLoaderComponent,
    StatusIconComponent,
    TooltipModule,
    CalendarModule
  ],
  exports: [
    InputTextModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    ProgressBarModule,
    SliderModule,
    DropdownModule,
    MultiSelectModule,
    TabViewModule,
    ButtonModule,
    InputSwitchModule,
    DialogModule,
    PasswordModule,
    CardModule,
    ConfirmDialogModule,
    CalendarModule,
    ImageModule,
    NgxSpinnerModule,
    RadioButtonModule,
    ToastModule,
    CarouselModule,
    TimelineModule,
    FileUploadModule,
    GooglePlaceModule,
    ToggleButtonModule,
    InputTextareaModule,
    FieldsetModule,
    ChartModule,
    PanelModule,
    SkeletonModule,
    CardModule,
    DividerModule,
    SidebarModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextareaModule,
    ProgressSpinnerModule,
    CustomUploaderComponent,
    CustomValidationDirective,
    CommonLoaderComponent,
    StatusIconComponent,
    TooltipModule,
    CalendarModule
  ],
  providers:[DatePipe,MessageService]
})
export class SharedModule { }
