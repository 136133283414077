import { ConvertFromEnumToType } from "../types/enum.types";

export function indexEnumValue(enumData: any, key: string) {
  return Object.keys(enumData).indexOf(key);
}

export function _indexEnumValue<T extends Record<string, unknown>>(
  enumData: T,
  key: keyof T
): number | null {
  const values = Object.values(enumData);
  const index = values.findIndex((value) => value == enumData[key]);
  return index !== -1 ? index : null;
}


// Function to convert an enum to an array of key-value objects
export function enumToKeyValuePairArray<E extends object>(enumObject: E): ConvertFromEnumToType<E> {
  const enumKeys = Object.keys(enumObject)
      .filter(key => isNaN(parseInt(key, 10))); // Filter out numeric keys

  return enumKeys.map(key => ({
      key: key as keyof E,
      value: enumObject[key as keyof E]
  })) as ConvertFromEnumToType<E>;
}
